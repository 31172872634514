











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Badge extends Vue {
  @Prop({
    type: Number,
    required: true,
  }) private readonly number!: number

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly noticed!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly muted!: boolean

  @Prop({
    type: Number,
    default: 0,
  }) private readonly max!: number

  private get label (): string {
    return this.max && this.max < this.number
      ? `${this.max}+`
      : `${this.number}`
  }
}
